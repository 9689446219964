/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
// import { VscEmptyWindow } from 'react-icons/vsc';
// import { ReactI18NextChild } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { PaginationType } from '../../hooks/useFetch';
import { Paginator } from '../paginator';
import { ViewButton } from '../buttons/ViewButton';
import { EditButton } from '../buttons/EditButton';
import ModalForm from '../ModalForm';
import Loader from '../Loader';
import ConfirmModal from '../ConfirmModal';
import EmptySvg from '../EmptySvg';

interface RowActionsProps {
  show?: boolean;
  edit?: boolean;
  delete?: boolean;
}

interface FormatColumnProps {
  field: string;
  formatField: any;
}

interface DataGridProps {
  columns: any;
  rows: any;
  requestApi: any;
  error: any;
  baseUrl: string;
  data: any;
  loading: boolean;
  hasCreate?: boolean;
  getParam?: any;
  createDescription?: string;
  modalForm?: any;
  rowActions?: RowActionsProps;
  NewRowActions?: any;
  headerActions?: any;
  editAction?: any;
  didDelete?: any;
  headerFilters?: any[];
  formatColum?: FormatColumnProps[];
  doneRow?: string;
  limitFirstColumn?: boolean;
  smallerModal?: boolean;
}

export const DataGrid = ({
  columns,
  rows,
  requestApi,
  error,
  baseUrl,
  data,
  loading,
  hasCreate,
  getParam = true,
  createDescription,
  modalForm,
  rowActions,
  NewRowActions,
  headerActions,
  headerFilters,
  formatColum = [],
  doneRow,
  editAction,
  didDelete,
  limitFirstColumn,
  smallerModal,
}: DataGridProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pagination, setPagination] = useState<PaginationType>({} as PaginationType);
  const [itemIdUrl, setItemIdUrl] = useState<string>('');
  const [update, setUpdate] = useState(false);
  const [queryParams, setQueryParams] = useState<any>({
    page: pagination?.current_page ?? 1,
    per_page: 10,
  });

  const handleDeleteItem = async (itemId: string) => {
    await requestApi('delete', null, `${baseUrl}/${itemId}`);
    setUpdate(old => !old);
    if (didDelete) didDelete();
    console.log(error);
    // if (error.msg === undefined) {
    // await requestApi('get', { params: queryParams }, `${baseUrl}${location.pathname}`);
    // }
  };

  useEffect(() => {
    if (!isOpen && getParam) {
      requestApi('get', { params: queryParams });
    }
  }, [queryParams, isOpen, update, getParam]);

  useEffect(() => {
    setPagination(data?.pagination);
  }, [data]);

  useEffect(() => {
    if (!isOpen) {
      setItemIdUrl('');
    }
  }, [isOpen]);

  return (
    <Box>
      <Flex mb={5} align="center" justify="space-between">
        <Flex align="center">
          {headerFilters?.map(filterProps => (
            <Box key={filterProps.name} mr={4}>
              <filterProps.filterComponent
                isDisabled={data?.data?.length <= 0 && !queryParams[filterProps.name]}
                // forcing pagination to 1 because data may be in page 1 from search
                onChange={value =>
                  setQueryParams({
                    page: 1,
                    per_page: 10,
                    [filterProps.name]: value,
                  })
                }
                options={filterProps.options}
                placeholder={filterProps.placeholder}
              />
            </Box>
          ))}
        </Flex>

        {hasCreate && (
          <Flex align="center" marginLeft="auto">
            <ModalForm
              isOpen={isOpen}
              onClose={onClose}
              RenderedForm={modalForm}
              itemIdUrl={itemIdUrl}
              onClick={onOpen}
              description={createDescription}
              smallerModal={smallerModal}
            />
          </Flex>
        )}
      </Flex>
      {headerActions && (
        <Flex align="center" marginLeft="auto">
          {headerActions}
        </Flex>
      )}
      {!loading ? (
        <>
          {data?.data?.length > 0 ? (
            <>
              <TableContainer
                border="1px solid"
                borderColor="gray.100"
                borderRadius="25px"
                boxShadow="0px 5px 10px #00000029"
              >
                <Table>
                  <Thead bg="#EFEFEF 0% 0% no-repeat padding-box">
                    <Tr>
                      {columns?.map(item => (
                        <Th key={item.id ?? item.name}>{item.label}</Th>
                      ))}
                      <Th>Ações</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {!loading ? (
                      <>
                        {rows?.map(item => (
                          <Tr
                            key={item.id}
                            style={
                              !!doneRow && item.status === doneRow
                                ? { textDecoration: 'line-through' }
                                : {}
                            }
                          >
                            {columns?.map((dataItem, index) => (
                              <Td
                                key={dataItem.name}
                                style={
                                  index === 0 && limitFirstColumn
                                    ? {
                                        maxWidth: '420px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }
                                    : {}
                                }
                                _hover={{ whiteSpace: 'break-spaces' }}
                              >
                                {formatColum.length > 0
                                  ? formatColum
                                      .filter(value => value.field === dataItem.name)
                                      ?.shift()
                                      ?.formatField(item[dataItem?.name]) ?? item[dataItem.name]
                                  : item[dataItem.name]}
                              </Td>
                            ))}
                            <Td>
                              <Flex>
                                {rowActions?.show && (
                                  <ViewButton
                                    onClick={() => {
                                      navigate(`${location.pathname}/${item.id}`, {
                                        replace: true,
                                      });
                                    }}
                                  />
                                )}

                                {rowActions?.edit && (
                                  <EditButton
                                    onClick={() => {
                                      if (editAction) editAction(item);
                                      setItemIdUrl(`${baseUrl}/${item.id}`);
                                      onOpen();
                                    }}
                                  />
                                )}

                                {rowActions?.delete && (
                                  <ConfirmModal
                                    handleDeleteItem={handleDeleteItem}
                                    itemId={item.id}
                                  />
                                )}

                                {NewRowActions ? <NewRowActions itemId={item.id} /> : null}
                              </Flex>
                            </Td>
                          </Tr>
                        ))}
                      </>
                    ) : null}
                  </Tbody>
                </Table>
              </TableContainer>

              {data?.total_items > queryParams?.per_page || 10 ? (
                <Paginator
                  pagination={pagination}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                />
              ) : null}
            </>
          ) : (
            <Flex h="50vh" align="center" justify="center">
              <EmptySvg />
              <Heading
                size="lg"
                maxW="300px"
                color="#4E5B88"
                ml="70px"
                textAlign="left"
                fontWeight="medium"
              >
                Não há dados a serem exibidos
              </Heading>
            </Flex>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};
