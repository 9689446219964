import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { STORAGE_KEYS } from '../../constants/Config';
import { useFetch } from '../../hooks/useFetch';
import { useUnit } from '../../hooks/useUnit';
import { getAuthStorage } from '../../services/storage';

export default function SelectUnitPage() {
  const baseUrlClient = '/v1/admin/clients';
  const { requestApi: reqApiClient, data: dataClient } = useFetch(baseUrlClient);
  const { updateClient, updateUnit } = useUnit();
  const baseUrlUnit = '/v1/admin/units';
  const { requestApi: reqApiUnit, data: dataUnit } = useFetch(baseUrlUnit);
  const navigate = useNavigate();
  const [clientLocalStorage, setClientLocalStorage] = useState<number | null>(null);
  const [unitLocalStorage, setUnitLocalStorage] = useState<number | null>(null);

  async function getLocalStorageThenApiClient() {
    // checking localstorage first then req
    try {
      const clientLocal = await getAuthStorage(STORAGE_KEYS.CLIENT);
      const unitLocal = await getAuthStorage(STORAGE_KEYS.UNIT);
      setClientLocalStorage(clientLocal);
      setUnitLocalStorage(unitLocal);
    } catch (error) {
      setClientLocalStorage(null);
      setUnitLocalStorage(null);
    } finally {
      reqApiClient('get', {
        params: { page: 1, per_page: 100 },
      });
    }
  }

  useEffect(() => {
    getLocalStorageThenApiClient();
  }, []);

  useEffect(() => {
    if (dataClient?.data?.length > 0) {
      updateClient(clientLocalStorage || Number(dataClient?.data[0]?.id)).then(() => {
        reqApiUnit('get', null, `${baseUrlUnit}?client_id=${dataClient?.data[0]?.id}`);
      });
    }
  }, [dataClient]);

  useEffect(() => {
    if (dataUnit?.data?.length > 0) {
      updateUnit(unitLocalStorage || Number(dataUnit?.data[0]?.id)).then(() => {
        navigate('/numeros');
      });
    }
  }, [dataUnit]);
}
